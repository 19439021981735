import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/QuizClub/inquiztive/1.jpg';
import p2 from 'assests/Photos/Clubs/QuizClub/inquiztive/2.jpg';
import p3 from 'assests/Photos/Clubs/QuizClub/inquiztive/3.jpg';
import p4 from 'assests/Photos/Clubs/QuizClub/inquiztive/4.jpg';

import CyberClub from 'views/CyberClub';


import { Typography } from '@mui/material';
import Container from 'components/Container';


const Inquizitive2022 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
   
  


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      INQUIZITIVE
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
    
DATE: 20/01/23
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Quiz Association of National Public School Yeshwanthpur conducted ‘INQUIZITIVE’ a General Knowledge Quiz for the students of Classes 9 and 11 on Friday, 20 January 2023. Four students from each house were shortlisted for the Finals based on the points scored in the Preliminary Round. The questions ranged from Current Affairs, History, Business, Science and Technology.
        <br/><br/>
        The teams gave tough competition to each other and it was a close call till the end. They found the quiz very interesting and informative. The participants as well as the audience thoroughly enjoyed the quiz and felt it was a great learning experience. They learnt that keeping themselves updated about current affairs using critical thinking and logical reasoning are very important to becoming an avid quizzer. 

        <br></br>
         

        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
    STAY INQUISITIVE WITH A THIRST FOR KNOWLEDGE TO ACQUIRE LEARNING OF ANY KIND
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <CyberClub />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default Inquizitive2022;